// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";


// start : custom css here
.err-msg {
    color: red;
    font-weight: bold;
    margin-top: 3px;
  }
  .error {
    float: left;
    width: 100%;
  }
  .boldLable {
    font-weight: bold;
  }
  /*
   server-side-angular-way.component.css
*/
.no-data-available {
  text-align: center;
}

/*
   src/styles.css (i.e. your global style)
*/
// .dataTables_empty {
//   display: none;
// }

.server-side >tr>td.dataTables_empty {
  display: none;
}

// side bar blank icon
.nav-pl a{padding-left: 42px !important;}

.table-full-width{
  width: 100%!important;
}

.custom-avatar{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.required-sign{
  color: red;
}
.preview-image{
  width: 150px;
  height: 150px;
}
.view-details-table th{
  width: 20%!important;
}

.view-details-table {
  max-width: 80%!important;
}
// .subservice-module
a.active-link.nav-link.subservice-module {
  color: #20a8d8!important; 
}  

.close-btn {
  color: red
}



.table-btn tr td:last-child {
  // white-space: normal !important;
  width:60%;
}


.overlay .backdrop {
  position: fixed !important;
}

.dataTable tbody tr td.actions{white-space: nowrap;}