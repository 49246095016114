// Here you can add other styles

/* css reset*/
ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
/* css reset*/

// .sidebar .sidebar-nav,
// .sidebar .nav {
//  width: 270px;
// }
// .sidebar .nav{
//     background-color: #452782;
// }

.sidebar .nav-link {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

.form-control[disabled] {
  background-color: #fff !important;
  opacity: 1;
}

.customColorActive {
  color: #ea2929;
}

.sidebar .nav-link .nav-icon {
  position: relative;
  top: 5px;
  float: left;
}
.sidebar .sidebar-minimizer {
  flex: 0 0 40px;
}
.sidebar .sidebar-minimizer::before {
  width: 40px;
  height: 40px;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -270px;
}

.sidebar .nav-link .nav-icon {
  vertical-align: middle;
}
.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
  background: #642835;
}
.sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
  background: #642835;
}

// .breadcrumb {
//     display: none;
// }
// .main {
//     padding-top: 30px;
// }
.app-header .navbar-nav {
  margin-right: 20px;
}

.nav.navbar-nav ~ button {
  display: none !important;
}

.responsive-table {
  overflow: auto;
}

.form-control.disabled {
  background-color: #d4d7da;
  opacity: 1;
  pointer-events: none;
}

.card-body.view-history td {
  width: 100%;
  display: inline-block;
  padding: 15px;
}

.card-body.view-history td strong {
  text-transform: capitalize;
  margin-right: 8px;
}
table {
  margin-bottom: 0px !important;
}

table th,
table td {
  vertical-align: middle !important;
}

table th {
  border-top: none !important;
}

table tbody tr:first-child td {
  border-top: none;
}

.responsive-table table {
  min-width: 838px;
  width: 100% !important;
}

.scan-view-table table {
  min-width: 480px;
}

.scan-view-table table td:first-child {
  width: 200px;
}

.form-group label,
.card-header {
  font-weight: bold;
}

.filter-dropdown {
  font-weight: bold;
}

.logo-part {
  display: inline-block;
  width: 255px;
  height: 100px;
  background: linear-gradient(135deg, #76be45, #5e9b33);
  padding: 15px;
  margin-bottom: 40px;
}

.logo-part img {
  width: 100%;
  height: 100%;
}

img.navbar-brand-full {
  width: 100%;
  background: linear-gradient(135deg, #76be45, #5e9b33);
  padding: 11px;
  height: 54px;
  object-fit: contain;
}

.color-dark {
  color: #171717;
}

.user-view h3 {
  margin: 20px 0;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: bold;
}
.card-header {
  font-size: 18px;
  text-transform: capitalize;
}

.user-image {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
}

.user-image img {
  width: 100%;
  height: 100%;
}

.user-view tr td i {
  margin: 0 8px;
  color: #000;
}

.user-view tr td a {
  color: #20a8d8 !important;
  cursor: pointer;
}

.btn {
  text-transform: capitalize;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #2f353a;
  border-color: #2f353a;
}

.page-link {
  color: #2f353a;
}

.nav-link {
  text-transform: capitalize;
}

/*.table {
  border-radius: 5px;
  overflow: hidden;
}*/

.home-data h4 {
  font-weight: normal;
  margin-bottom: 0;
  font-size: 20px;
}

.icon-common {
  display: inline-block;
  margin: 0 8px;
  font-size: 20px;
  cursor: pointer;
}

.breadcrumb li:first-child {
  display: none;
}

.breadcrumb-item + .breadcrumb-item:nth-child(2)::before {
  display: none;
}

.card-box {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  padding: 11px 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
}

.category-list {
  display: inline-block;
  padding-bottom: 30px;
  width: 100%;
}

.category-list li {
  margin-bottom: 20px;
}

.sortable-ghost .card-box {
  background-color: rgba(241, 241, 241, 1);
}

.card-box h4 {
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
  color: #000;
  border-bottom: 2px solid transparent;
}

.card-box h4:hover {
  border-bottom-color: #000;
}

.category-list li:last-child {
  margin-bottom: 0;
}

a:hover {
  text-decoration: none;
}

.sub-category-list {
  width: 100%;
  margin-bottom: 30px;
}
.sub-category-list .card-box {
  width: 100%;
}
.workshop-list .card-box {
  width: 100%;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #262526;
  border-color: #262526;
}

.speaker-image {
  display: inline-block;
  width: 220px;
  height: 94px;
}

.speaker-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.breadcrumb-item a {
  color: #000;
  font-weight: 500;
}
.sidebar .nav-link:hover {
  color: #fff;
  background: #5c9930;
}
.sidebar .nav-link.active {
  color: #fff;
  background: #70b53f;
}
.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.multiple-files {
  display: flex;
  width: 100%;
  margin-top: 10px;
  flex-wrap: wrap;
}
.multiple-files li {
  display: flex;
  width: 30.33%;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-right: 14px;
  margin-bottom: 10px;
}
.multiple-files .box {
  display: flex;
  width: 100%;
  padding: 8px 5px 9px;
  justify-content: center;
  position: relative;
}
.box h4 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  width: 107px;
}
.multiple-files .box i {
  position: absolute;
  right: 5px;
  top: 3px;
}
// .nav-dropdown-items {
//  // width: 230px;
//     // float: right;
// }
.err-msg {
  color: red;
  font-weight: bold;
  margin-top: 3px;
}
.error {
  float: left;
  width: 100%;
}
.err-msg-modal {
  color: red;
  font-weight: bold;
  margin-top: -10px;
  float: left;
}
.ng-star-inserted + .odd .dataTables_empty {
  display: none;
}
.no-data-available {
  text-align: center;
}
.action-table {
  td,
  th {
    &:last-child {
      width: 150px;
      text-align: center;
    }
  }
}

/*--- Data Table ---*/
.card-body > .d-flex .form-group {
  z-index: 1;
  position: relative;
}
.top-margin {
  margin-top: -60px !important;
}

table.dataTable {
  border-collapse: collapse;

  &.table-filter {
    thead,
    tbody {
      th,
      td {
        &:first-child {
          max-width: 80px;
          text-align: center;
          padding-left: 15px;
        }
      }
    }
  }

  thead,
  tbody {
    th,
    td {
      padding: 15px 15px;
      vertical-align: middle;
      border: 1px solid #e4e7ea !important;

      &:first-child {
        padding-left: 30px;
      }

      &:last-child {
        &:nth-child(2) {
          text-align: center;
          max-width: 150px;
          width: 150px !important;
        }
      }
    }

    th {
      color: $gray-800;
      font-weight: $font-weight-bold;
      background-position: center right 10px !important;
      vertical-align: top;
      white-space: nowrap;
      padding-right: 25px !important;

      &.sorting {
        background-image: url("assets/img/icn-short.svg");
      }

      &.sorting_asc {
        background-image: url("assets/img/icn-short-asc.svg");
      }

      &.sorting_desc {
        background-image: url("assets/img/icn-short-desc.svg");
      }
    }
  }
}

.dataTables_wrapper {
  .dataTables_filter {
    margin-bottom: 1rem;
    float: left;
    label {
      display: flex;
      align-items: center;
    }
    input {
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #5c6873;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e4e7ea;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        outline: none;
      }
    }
  }

  .dataTables_length {
    margin-right: 30px;
    margin-bottom: 20px;
    label {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      select {
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #5c6873;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #e4e7ea;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin: 0px 5px;
        max-width: 100px;
      }
    }
  }

  .dataTables_info {
    padding-left: 30px;
    padding-top: 10px;
  }

  .dataTables_paginate {
    padding-top: 10px;
    padding-right: 30px;

    .paginate_button {
      border: none !important;
      background: #e7e7e7;
      color: $secondary;
      width: 24px;
      padding: 0px;
      margin-left: 5px;
      line-height: 24px;
      border-radius: 0px;

      &:hover,
      &.current {
        color: $white !important;
        background: $primary !important;
      }

      &.current {
        &:hover {
          color: $white !important;
        }
      }

      &.previous,
      &.next,
      &.first,
      &.last {
        font-size: 0px;
        position: relative;
        vertical-align: top;
        background-color: #e7e7e7 !important;

        &.disabled,
        &:hover {
          pointer-events: none;
          opacity: 0.7;
        }

        &:before {
          content: "\e605";
          background: transparent none no-repeat scroll 0 0;
          display: inline-block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          font-family: simple-line-icons;
          font-style: normal;
          font-weight: 400;
          font-variant: normal;
          text-transform: none;
          line-height: 24px;
          font-size: 10px;
        }
      }
      &.last,
      &.first {
        &::before {
          font: normal normal normal 22px/1 FontAwesome !important;
          font-size: inherit;
          text-rendering: auto;
          line-height: 24px;
          opacity: 0.4;
        }
        &.first {
          &::before {
            content: "\f100" !important;
          }
        }
        &.last {
          &::before {
            content: "\f101" !important;
          }
        }
      }
      &.next {
        &:before {
          content: "\e606";
        }
      }
    }
  }
}
.drag-row {
  thead,
  tbody {
    th,
    td {
      &:nth-child(3),
      &:nth-child(2) {
        text-align: center;
        max-width: 150px;
      }
    }
  }
}

.table-filter .form-check {
  display: inline-block;
  width: 13px;
  height: 13px;
  vertical-align: middle;

  .form-check-input {
    margin: 0px !important;
    left: 0px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
}

.form-dual {
  margin: 0 -25px;

  .form-full {
    padding: 0 25px;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .form-half {
    padding: 0 25px;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .form-block {
    position: relative;
    border-bottom: 1px solid orange;
  }
}
// .form-group .form-control {
//     font-size: 16px;
//     color: #000000;
//     width: 100%;
//     height: auto;
//     line-height: normal;
//     border: 0;
//     padding: 23px 0px 6px;
//     background-color: transparent;
//     position: relative;
//     z-index: 1;
// }
.form-group .form-label {
  position: absolute;
  left: 0px;
  top: 20px;
  font-size: 16px;
  color: orange;
  transition: all 0.3s ease-in-out;
}
.form-group .form-control:not(:placeholder-shown) + .form-label,
.form-group .form-control:focus + label.form-label {
  top: 8px;
  font-size: 12px;
}
.form-control:focus {
  box-shadow: none;
}
.text-orange {
  color: orange;
}
.file-label {
  cursor: pointer;
}
.file-label-select input {
  opacity: 0;
  font-size: 0;
  width: 0;
  height: 0;
}
.form-group .form-control::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0;
}
.form-group .form-control:-moz-placeholder {
  color: #ffffff;
  opacity: 0;
}
.form-group .form-control::-moz-placeholder {
  color: #ffffff;
  opacity: 0;
}
.form-group .form-control:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0;
}
.file-label-select {
  #input-logo {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.icon-plush {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: orange;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  img {
    width: 15px;
    height: 15px;
  }
}

.payment-form-step {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 50px 0;
  min-width: 420px;
}

.tours-bar-payment {
  width: 540px;
  li {
    position: relative;
    flex: 1;
    height: 2px;
    background-color: $black;
    &:first-child {
      flex: 0;
    }
    .dots-subscribe {
      position: absolute;
      top: -25px;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%) scale(0.75);
    }
    &.active {
      background-color: orange;
      height: 6px;
      .tours-dot-payment {
        background-color: orange;
        top: -3px;
      }
    }
  }
}

.tours-dot-payment {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #000000;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transform-origin: right;
  transform-origin: right;
  position: absolute;
  top: -5px;
  right: -9px;
  z-index: 1;
}

.card-employees {
  padding: 15px 55px 15px 15px;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  background-color: $white;
  .card-email {
    margin-bottom: 0;
  }
  [class^="icon-"] {
    position: absolute;
    top: 20px;
    right: 50px;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .fa-trash-o {
    position: absolute;
    right: 30px;
    top: 20px;
  }
}
.row {
  &.padding-b20 {
    [class^="col-"] {
      padding-bottom: 20px;
    }
  }
  &.padding-b10 {
    [class^="col-"] {
      padding-bottom: 10px;
    }
  }
}
.text-orange {
  color: orange;
}
.card-height {
  max-height: calc(100vh - 100px);
  overflow: auto;
}
// .tours-bar-payment li.active .dots-subscribe {
//     color: orange;
// }
// .tours-bar-payment li.active::after {
//     content: '';
//     right: calc(100% + 4px);
//     height: 5px;
//     background-color: orange;
//     width: 159px;
//     position: absolute;
//     top: -2px;
//     bottom: auto;
//     border-radius: 4px;
// }
// .tours-bar-payment li.active:first-child::after {
//     content: '';
//     right: calc(100% + -170px);
//     height: 5px;
//     background-color: orange;
//     width: 0;
//     position: absolute;
//     top: -2px;
//     bottom: auto;
//     border-radius: 4px;
// }

.services-list li .cus-design-check input {
  opacity: 0;
  width: 0;
  height: 0;
  font-size: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.services-list li .cus-design-check label {
  margin-bottom: 0;
  padding: 20px 10px;
  background-color: orange;
  color: #ffffff;
  font-size: 18px;
  width: 100%;
  border-radius: 6px;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
}
.services-list li .cus-design-check input:checked ~ label {
  opacity: 1;
}

// .switch {
//   position: relative;
//   display: inline-block;
//   width: 60px;
//   height: 34px;
// }

// .switch input {
//   opacity: 0;
//   width: 0;
//   height: 0;
// }

// .slider {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #ccc;
//   -webkit-transition: .4s;
//   transition: .4s;
// }

// .slider:before {
//   position: absolute;
//   content: "";
//   height: 26px;
//   width: 26px;
//   left: 4px;
//   bottom: 4px;
//   background-color: white;
//   -webkit-transition: .4s;
//   transition: .4s;
// }

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.work-areas-list {
  width: 100%;
  li {
    width: 100%;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }

    .content {
      span {
        font-weight: bold;
      }
      label {
        margin-bottom: 0;
      }
    }
  }
}
.accordion-toggle {
  .btn {
    padding: 0;
    border: 0;
    text-decoration: none;
  }
}
.company-img {
  width: 200px;
  height: 200px;
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.business-main {
  .business-list {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }
    .icon-pencil {
      margin-top: 3px;
    }
  }
}
.input-group agm-map {
  width: 100%;
  height: 200px !important;
  z-index: 9;
}
.company-address {
  width: calc(100% - 200px);
  padding: 0 0 0 15px;
  margin-bottom: 0;
  h5 {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 10px;
  }
}
.form-select {
  .dropdown-list {
    margin-top: 0 !important;
  }
  // .multiselect-item-checkbox {
  //     input[type="checkbox"] {
  //         &+ div {
  //             &::before {
  //                 color: #ffa500 !important;
  //                 border: 2px solid #ffa500 !important;
  //             }
  //         }
  //         &:checked {
  //             &+ div {
  //                 &::before {
  //                     -webkit-animation: .2s ease-in borderscales !important;
  //                     animation: .2s ease-in borderscales !important;
  //                     background: #ffa500 !important;
  //                 }
  //             }
  //         }
  //     }
  // }
  .multiselect-dropdown {
    .dropdown-btn {
      border: 1px solid #e4e7ea !important;
      padding-right: 23px !important;
      // .selected-item {
      //     border: 1px solid #ffa500 !important;
      //     background: #ffa500 !important;
      // }
    }
  }
}
.flex-1 {
  -ms-flex: 1;
  -webkit-box-flex: 1;
  flex: 1;
}
.price-list {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  &:last-child {
    border-bottom: 0;
  }
}
.price-left {
  &:last-child {
    margin-bottom: 0;
  }
  h6 {
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.price-right {
  p {
    min-width: 26px;
    margin-left: 6px;
    margin-bottom: 0;
  }
  .rate-input {
    margin-left: 10px;
  }
  
}
.price-img {
  position: relative;
  display: block;
  padding-top: 100%;
  .img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
  }
}
.card-body .price-link {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #404748 !important;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.card-body .price-link:hover {
  color: #fdb856 !important;
}
.accordion {
  margin-bottom: 15px;
}
.accordion-header {
  background-color: #eee;
  padding: 10px;
  b {
    margin-right: 10px;
  }
  h4 {
    margin-bottom: 0;
  }
}
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  ul {
    padding: 10px;
  }
}
.accordion-button {
  position: relative;
  display: grid;
  place-items: center;
  padding: 0;
  border: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
  margin-left: 10px;
  background-color: orange;
  border-radius: 50%;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
  }
  &::before {
    height: 15px;
    width: 2px;
  }
  &::after {
    width: 16px;
    height: 2px;
  }
  &[aria-expanded="true"] {
    &::before {
      height: 0px;
    }
  }
}
.rate-input {
  width: 50px;
  text-align: center;
  border: 1px solid #e5e5e5;
  padding: 0;
}
.btn-orange {
  color: $white;
  background-color: orange;
  &:hover {
    color: $white;
    background-color: darken(orange, 5);
  }
}
.details-block {
  .picture {
    width: 320px;
    max-width: 100%;
    margin: 0 auto 30px;
  }
}
.remove-btn-fixed {
  position: relative;
  height: 100%;
  display: inline-block;
  width: 200px;
  height: 200px;
}
.remove-btn-fixed img {
  width: 100%;
  height: 100%;
}
.remove-btn-fixed .btn-remove {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 20px;
  padding: 0px 6px;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.upload-box {
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  cursor: pointer;
  margin-bottom: 0;
}
.upload-box .btn-add {
  font-size: 20px;
  padding: 0px 8px;
  height: 33px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: normal;
}
.upload-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.upload-picture img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.order-li {
  & > strong,
  & > span {
    padding: 7px 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    border-bottom-width: 0;
  }
  & > strong {
    width: 30%;
    border-right-width: 0;
  }
  & > span {
    width: 70%;
  }
  &:last-child {
    strong,
    span {
      border-bottom-width: 1px;
    }
  }
}
@mixin circle($circle: $boolean) {
  @if $circle {
    border-radius: 50%;
  }
}
.chain-main {
  li {
    position: relative;
    padding: 8px 0;
    &::before,
    &::after {
      content: "";
      width: 2px;
      height: calc(50% - 8px);
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: #ccc;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    &:first-child {
      &::before {
        display: none;
      }
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &.active {
      .chain-dot {
        background-color: green;
      }
    }
  }
}
.chain-dot {
  width: 8px;
  height: 8px;
  background-color: #333;
  @include circle(true);
}
.chain-time,
.chain-name {
  width: calc(50% - 8px);
}
svg {
  fill: currentColor;
}
.file-img {
  background-color: #fff;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: grid;
  place-items: center;
}
.file-img svg {
  height: 18px;
  color: #23282c;
}
.chat-self .file-img svg {
  color: #56a2f2;
}

.chat-ul {
  height: calc(100vh - 330px);
  overflow: auto;
  padding-right: 15px;
}
#IconCameraLine,
#icon-video-line {
  max-width: 18px;
}
.chat-ul .chat-li {
  max-width: calc(50% - 10px);
  margin-right: auto;
  margin-bottom: 6px;
}
.chat-ul .user-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 11px;
  display: inline-block;
}
.chat-ul .user-header {
  margin-bottom: 2px;
}
.chat-ul .chat-message p , .chat-msg-box .chat-message p  {
  padding-bottom: 0;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.04em;
  background-color: #f1f0f5;
  padding: 13px 17px 13px 14px;
  margin-bottom: 0;
  width: 100%;
  max-width: 100%;
}
.chat-msg-box .chat-message p{
  width: 550px;
}
.chat-message h6 {
  font-weight: bold;
}
.chat-ul .chart-img p {
  padding-top: 6px;
  padding-bottom: 7px;
}
.chat-ul.chat-stream .chat-message p {
  padding: 0;
  background-color: transparent;
  letter-spacing: 0.04em;
  color: #a0a3b9;
}
.chat-ul .chart-document p {
  padding-right: 34px;
}
.chat-ul .chat-self p {
  background-color: #56a2f2;
  color: #fff;
}
.chat-ul .chat-time {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #a0a3b9;
}
.chart-img {
  max-width: 260px;
}
.chart-img img {
  width: 100%;
  height: 155px;
  object-fit: cover;
}
.message-box {
  background-color: #fff;
  padding: 15px 0 0 0;
}
.message-box .message {
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  letter-spacing: 0.05em;
  color: #2b3a51;
  border: 0;
  height: 56px;
  width: 100%;
  resize: none;
  border: 1px solid #ccc;
}
.message-box .message:focus {
  outline: none;
  box-shadow: none;
}
.message-box .message-type {
  padding-right: 15px;
}
.message-box .message::placeholder {
  color: #e3e3e9;
}
.message-box .message::-webkit-input-placeholder {
  color: #e3e3e9;
}
.message-box .message::-moz-placeholder {
  color: #e3e3e9;
}
.message-box .message:-ms-input-placeholder {
  color: #e3e3e9;
}
.message-box .message:-moz-placeholder {
  color: #e3e3e9;
}
.message-box .icon-atteched {
  width: 17px;
  color: #c6c6d2;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.message-box button.icon-send {
  width: 20px;
  color: #c6c6d2;
  background-color: transparent;
  padding: 0;
  border: 0;
  margin-left: 15px;
  transition: all 0.3s ease-in-out;
}
.message-box button.icon-send:hover,
.message-box .icon-atteched:hover {
  color: #56a2f2;
}
.message-box .icon-atteched + input {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

// chat-view
.card .chat-main-box {
  background-color: #fff;
  position: relative;
  display: inline-block;
  width: 100%;
}
.card .chat-img {
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0;
}
.card .chat-img img,
.card .arrow-btn img {
  max-width: 100%;
  vertical-align: middle;
}
.card .chat-img img {
  height: 100%;
}
.card .chat-info h6 {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
}
.card .chat-info p {
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card .chat-info span {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
}
.card .chat-box {
  border-bottom: 1px solid grey;
  color: #2f353a;
  position: relative;
  padding: 7px 10px;
}
.card .arrow-btn {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 20px;
  cursor: pointer;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: top;
}
.card .left-panal {
  overflow: auto;
  max-height: 730px;
  height: 100vh;
}
.card .right-box {
  background-color: #fff;
  position: relative;
}
.card .left,
.card .right {
  text-align: left;
  font-size: 15px;
  background-color: aliceblue; /* display: inline-block; */
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.card .right {
  text-align: right;
  background-color: transparent;
}
.card .chat-deatils {
  padding: 20px;
  overflow: auto;
  height: 680px;
}
.card .chat-deatils .message-box .message {
  height: 40px;
}
.card .chat-deatils .message-box {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 20px 10px 20px;
  background-color: transparent;
}
// .card .chat-deatils .chat-warp {max-height: 450px;overflow: auto;}
.card .chat-box:hover {
  color: #2f353a !important;
}
.card .cancel-btn {
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 27px;
  display: none;
}
.card .header-name {
  display: inline-block;
}
.card .chat-box.active {
  background-color: #5c9930;
  color: #fff !important;
}
.card .chat-info {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 66px);
  padding-right: 125px;
}
.chat-msg-box .chat-message {
  width: 480px;
  position: relative;
}
.close-btn {
  color:black !important;
  font-size: 40px !important;
}
.image-box .img-avatar {
  height: 50px !important;
  margin: 0 5px !important;
}
.width-80 {
  width: 80%;
}
@-webkit-keyframes borderscales {
  50% {
    box-shadow: 0 0 0 2px #ffa500 !important;
  }
}
@keyframes borderscales {
  50% {
    box-shadow: 0 0 0 2px #ffa500 !important;
  }
}

.play-btn-up{ 
  background-color: transparent;
  border:1px solid #fff; 
  color:#fff !important; 
  display: block;
  text-align: center; 
  margin: 10px auto 0;
}
.blackplay-btn-up{
  display: inline-block; 
  margin: 10px 0px 15px;
  border:1px solid #000; 
  color: #000 !important;}
.video-btn-2{
  display: block;
}
.video-section .play-btn-up{
  background-color: #20a8d8;
}
.video-modal{ 
  .modal-content{
    background-color: transparent;
    border: none;}
}
.modal {
  iframe{
    min-height: 500px; 
    min-width: 730px;
  }
}
.video-box{
  min-height: 500px; 
  min-width: 730px;
}
.modal-dialog.video-modal{
    max-width: 750px !important;
  }
button:focus{
  outline: none;
}
.thanks-text{
  background-color: #fff;
  max-width: 750px;
  margin: auto;
  max-height: 500px;
  padding: 35px;
}
.play-btn-up{ 
  img{
    margin-left: 5px;
  }
}
.btn:focus, .btn.focus{outline: none; -webkit-box-shadow: none; -o-box-shadow: none; -moz-box-shadow: none; -ms-box-shadow: none; box-shadow: none;}
.modal.fade .modal-dialog,.fade{transition: none; -webkit-transition: none; -o-transition: none; -moz-transition: none; -ms-transition: none;}
.modal .close {font-size: 40px;}
.info-btn{
  padding: 0;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  line-height: 0; 
  position: relative;
  &:hover .tooltip-info,
  &:hover .triangle-down{
    display: block;
  }
  img{
    width: 100%; 
    height: 100%;
  }
  .triangle-down {
    width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid #000;
      position: absolute;
      left: 2px;
      display: none;
  }
    .tooltip-info {
      min-width: 280px;
      width: 100%;
      max-height: 500px;
      padding: 5px;
      color: #fff;
      text-align: left;
      background-color: #000;
      border-radius: 0.25rem;
      opacity: 1;
      position: absolute;
      left: -150px;
      top: 28px;
      z-index: 111;
      line-height: 1.5;
      display: none;
    }
}




// Responsive
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .card .col-xl-3.left-box {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .card .col-xl-9.right-box {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .card .left-box {
    border-right: 1px solid #c8ced3;
  }
}
@media (max-width: 1200px) {
  .card .left-box {
    border-right: 1px solid #c8ced3;
  }
}
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .sidebar-minimizer {
    height: 40px;
  }
  // .sidebar-fixed .sidebar {
  //     width: 270px;
  // }
  // html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
  // html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
  // html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
  // html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
  //     margin-left: 270px;
  // }

  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
    width: 290px;
    vertical-align: top;
  }
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
    width: 290px;
  }

  .sidebar-minimized .sidebar .nav-dropdown-items {
    width: 290px;
  }
  .sidebar-minimized .sidebar .nav-item {
    display: inline-block;
  }

  .sidebar-minimized .sidebar .nav-link .nav-icon {
    margin-right: 0px;
  }
  .sidebar-minimized .sidebar .nav-link .nav-icon {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 1199px) {
  .payment-scrollauto {
    padding: 0 50px;
    overflow: auto;
  }
  .tours-bar-payment {
    li {
      .dots-subscribe {
        top: -21px;
        transform: translateX(-50%) scale(0.64);
      }
    }
  }
}
@media (max-width: 1024px) {
  .chat-deatils .message-type {
    width: 85%;
  }
}
@media (max-width: 991px) {
  // .sidebar {
  //     width: 270px;
  // }
  .payment-form-step {
    padding: 50px 0 20px;
  }
}

@media (max-width: 767px) {
  .main {
    padding-top: 15px;
  }
  .main .container-fluid {
    padding: 0 15px;
  }
  .app-header .navbar-nav {
    margin-right: 5px;
  }
  .card-body {
    padding: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1rem !important;
  }
  .live-wrap {
    margin-top: 10px;
  }
  .breadcrumb {
    margin-top: -15px;
  }

  .top-margin {
    margin-top: 0px !important;
  }

  .dataTables_wrapper {
    overflow: auto;
    padding-bottom: 15px;
  }

  table.dataTable {
    min-width: 512px;
    thead,
    tbody {
      th,
      td {
        padding: 15px 10px;

        &:first-child {
          padding-left: 10px;
        }
      }
    }
  }

  .dataTables_wrapper {
    .dataTables_info {
      padding-left: 0px;
    }

    .dataTables_paginate {
      padding-right: 0px;
    }
  }
  .no-data-available {
    text-align: center;
  }
  .dataTables_empty {
    display: none;
  }

  .card .right-box {
    display: none;
  }

  .card .right-box.active {
    position: absolute;
    left: 0;
    right: 0;
    display: inline-block;
    height: 100%;
    z-index: 99;
  }

  .card .cancel-btn {
    display: block;
  }
}
@media (max-width: 575px) {
  .modal iframe {min-width: 290px;}
}
@media (max-width: 479px) {
  
  .multiple-files li {
    width: 100%;
  }
  .sub-category-list .card-box {
    width: 100%;
    margin-bottom: 15px;
  }
  .card-box h4 {
    font-size: 14px;
  }
  .sub-category-list {
    margin: auto;
  }
}

/*Arabic Language design changes*/
.body-ar {direction: rtl;text-align: right;}
.body-ar .forgot-password-text {text-align: left !important;}
.body-ar .language-box {margin-left: 0 !important;margin-right: auto !important;}
.body-ar .sidebar .nav-link .nav-icon {float: right;margin: 0 0 0 0.5rem;}
.body-ar .sidebar .nav-dropdown-toggle::before {right: inherit;left: 1rem;transform: rotate(180deg);-webkit-transform: rotate(180deg);}
.body-ar .sidebar .nav-dropdown.open > .nav-dropdown-toggle::before{transform: rotate(270deg);-webkit-transform: rotate(270deg);}
.body-ar table.dataTable thead th, .body-ar table.dataTable tbody th{background-position: center left 10px !important;}
.body-ar .dataTables_wrapper .dataTables_paginate {float: left; text-align: left; padding-right: 0; padding-left: 30px; }
.body-ar .dataTables_wrapper .dataTables_info {float: right;padding-left: 0;padding-right: 30px;}
.body-ar .breadcrumb-item + .breadcrumb-item {padding-left: 0;padding-right: 0.5rem;}
.body-ar .accordion-header b {margin-right: 0;margin-left: 10px;}
.body-ar .accordion-button, .body-ar .icon-plush {margin-left: 0;margin-right: 10px;}
.body-ar .close.close-btn {float: left;}
.body-ar .price-right p {margin-right: 15px;margin-left: 0;}
.body-ar .price-right .rate-input {margin-left: 0;margin-right: 10px;}
.body-ar .card-employees .fa-trash-o {right: inherit;left: 30px;}
.body-ar .card-employees {padding: 15px 15px 15px 55px;}
.body-ar .switch-3d.switch-sm .switch-input:checked ~ .switch-slider::before {-webkit-transform: translateX(-10px);transform: translateX(-10px);}
.body-ar .switch-3d .switch-slider::before{right: -1px;left: inherit;}
.body-ar .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .body-ar .btn-group > .btn-group:not(:last-child) > .btn {border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; border-top-left-radius: 0; border-bottom-left-radius: 0; }
.body-ar .btn-group > .btn:not(:first-child), .body-ar .btn-group > .btn-group:not(:first-child) > .btn {border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; border-bottom-right-radius: 0; }
.body-ar .btn-group > .btn:not(:first-child), .body-ar .btn-group > .btn-group:not(:first-child) {margin-right: -1px; margin-left: 0; }
.body-ar .cal-month-view .cal-day-cell:first-child {border-right-color: transparent; }
.body-ar .cal-month-view .cal-day-cell:last-child {border-right: 1px solid;border-right-color: #e1e1e1;}
.body-ar .cal-month-view .cal-day-number {float: left;margin-left: 15px;margin-right: 0;}
.body-ar .cal-month-view .cal-day-badge {float: right; margin-left: 0; margin-right: 10px; }
.body-ar .cal-month-view .cal-events {justify-content: flex-end; }
.body-ar .card .chat-info {margin-left: 0 !important;margin-right: 1rem !important;padding-left: 125px;padding-right: 0}
.body-ar .card .chat-info span {left: 0;right: inherit;}
.body-ar .message-box .message-type {padding-right: 0;padding-left: 15px;}
.body-ar .message-box button.icon-send {margin-right: 15px;margin-left: 0;}
.body-ar .file-img {margin-right: 0;margin-left: 8px;}
.body-ar .chat-ul .chart-document p {padding-right: 17px;padding-left: 34px;}
html:not([dir="rtl"]) .body-ar .sidebar {margin-right: -270px;margin-left: 0;}
html:not([dir="rtl"]) .body-ar.sidebar-show .sidebar, html:not([dir="rtl"]) .body-ar.sidebar-show .sidebar{margin-right: 0;}
.body-ar .app-header .navbar-nav .dropdown-menu {position: absolute;transform: translateX(130px) !important;text-align: right;}
.body-ar .dropdown-item i {margin-right: -10px;margin-left: 10px;}
.body-ar .dataTables_wrapper .dataTables_filter input {margin-left: 0;margin-right: 3px;}
.body-ar .dropdown-list ul li {text-align: right !important;}
.body-ar .multiselect-item-checkbox input[type="checkbox"] + div::before {left: inherit !important;right: 0 !important;}
.body-ar .multiselect-item-checkbox input[type="checkbox"] + div{padding-right: 2em !important;padding-left: 0 !important;}
.body-ar .multiselect-item-checkbox input[type="checkbox"] + div::after{left: inherit !important;right: 4px !important;}
.body-ar .multiselect-dropdown .dropdown-btn {text-align: right !important;}
.body-ar .multiselect-dropdown .dropdown-btn .selected-item {float: right !important;}
.body-ar .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {right: inherit !important;left: 1px !important;}
.body-ar .switch-input:checked ~ .switch-slider::before{-webkit-transform: translateX(-14px);transform: translateX(-14px);}
.accordion-header{align-items: center;}
@media (min-width: 992px) {
  .body-ar .app-body .main {margin-left: 0 !important;margin-right: 200px;}
  html:not([dir="rtl"]) .body-ar .sidebar {margin-right: 0;margin-left: 0;}
}
@media (max-width: 572px) {
  .app-header .navbar-brand{width: 100px;margin-left: 0;right: inherit;left: 45px;}
  .body-ar .app-header .navbar-brand{left: inherit;right: 45px;}
  #map {width: 100% !important;margin: 1rem 0 0;}
  .accordion-header h4 {font-size: 16px;}
}

.page-template-text-template .text-editor ol .main-title{font-family: 'Poppins'; font-weight: bold; color: #1A1A1A;}
.page-template-text-template .text-editor ol .main-title::before{font-weight: bold; color: #1A1A1A;}
.page-template-text-template .text-editor ol{counter-reset: item;}
.page-template-text-template .text-editor ol li ol{margin-top: 10px;}
.page-template-text-template .text-editor ol li{padding-left: 30px; color: #666;font-family: 'Poppins';
  font-weight: normal;position: relative;}
.page-template-text-template .text-editor ol li ol li {padding-left: 35px;}
.page-template-text-template .text-editor ol li ol li ol li{padding-left:55px;}
.page-template-text-template .text-editor ol li::before{position: absolute;left: 0; top: 0; content: counters(item, ".") " "; counter-increment: item;color: #666;}

 .page-template-text-template.list-section .text-editor .list-number li::before{display: none;}
// .page-template-text-template.list-section .text-editor .list-number li{list-style: decimal;}
// .list-section .text-editor ul li::before{position: absolute !important; content: "."!important ;font-size: 40px;line-height: 0;}
.list-section .text-editor ol li{padding-left: 5px;}
.page-template-text-template.list-section .text-editor ol .main-title::before{ font-weight: bold; color: #1A1A1A;}


/*--- Team Section ---*/
.team-section h2 {margin-bottom: 35px;}
.team-section .box h5 {margin-bottom: 29px;}
.text-editor :first-child {
  margin-top: 0px;
}
.text-editor h2 {
  margin-bottom: 31px;
}
.text-editor ul li, .text-editor ol li {
  padding-left: 55px;
  position: relative;
  margin-bottom: 14px;
}
.text-editor ol li:last-child {
  margin-bottom: 0px;
}
.editor-section{padding: 50px;}

.body-ar .page-template-text-template .text-editor ol li::before{left: auto; right: -25px;}


.table-box table {
  border:1px solid #dddddd;
  border-collapse: collapse;
  width: 100%;
}

.table-box td,.table-box th  {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

